import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import axios from "axios"
import { navigate } from "gatsby"
import {
  StyledForm,
  StyledFieldset,
  StyledInput,
  StyledLabel,
  StyledLabelHeader,
  StyledFormElementWrapper,
  StyledTextArea,
  ErrorMessage
} from "../styledComponents/contact"
import { StyledButton } from "../styledComponents/button"
import addToMailchimp from 'gatsby-plugin-mailchimp'

const FhtjHealingAccessCodeForm = () => {
  const [token, setToken] = useState("") // store token
  const [isSuccessMessage, setIsSuccessMessage] = useState(false) // manage is success message state
  const [messageSent, setMessageSent] = useState(false) // manage sent message state
  const [isFormValid, setIsFormValid] = useState(false)

  // Validation
  const validate = values => {
    const errors = {}
    if (!values.firstName) {
      errors.firstName = "Required"
    }
    if (!values.lastName) {
      errors.lastName = "Required"
    }

    if (!values.healthgoal1) {
      errors.healthgoal1 = "Required"
    }
   
    if (!values.email) {
      errors.email = "Required"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address"
    }


    Object.keys(errors).length ? setIsFormValid(false) : setIsFormValid(true)
    return errors
  }

  // this effect function authenticates our subcriber user to get a token
  useEffect(() => {
    axios({
      method: "post",
      url: `https://adminwp.karinagrant.co.uk/wp-json/jwt-auth/v1/token`,
      data: {
        username: `contactform`, // provide a user credential with subscriber role
        password: `qR9zizJq^qKbv2#5nopD8ly#`,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setToken(response.data.token)
      })
      .catch(error => console.error("Error", error))
  }, [])

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const {
    handleChange,
    handleBlur,
    isSubmitting,
    values,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      healthgoal1: "",
      healthgoal2: "",
      healthgoal3: "",
      howHear: "FMTJ",
    },
    validate,
    onSubmit: (
      {
        firstName,
        lastName,
        email,
        healthgoal1,
        healthgoal2,
        healthgoal3,
      },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)
      addToMailchimp(values.email, {
        firstName: values.firstName,
        lastName: values.lastName,
        howHEar: 'FMTJ',
        FHTJHAC: 'true'
      }
      )
      const bodyFormData = new FormData()
      bodyFormData.set("firstName", firstName)
      bodyFormData.set("lastName", lastName)
      bodyFormData.set("email", email)
      bodyFormData.set("healthgoal1", healthgoal1)
      bodyFormData.set("healthgoal2", healthgoal2)
      bodyFormData.set("healthgoal3", healthgoal3)

      // here we sent
      axios({
        method: "post",
        url: `https://adminwp.karinagrant.co.uk/wp-json/contact-form-7/v1/contact-forms/10983/feedback`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          // actions taken when submission goes OK
          resetForm()
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(true)
          navigate("/bookings/fhtj/thank-you-healing-access-code")
        })
        .catch(error => {
          // actions taken when submission goes wrong
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(false)
        })
    },
  })

  useEffect(() => {
    // set timeout 3 seconds to remove error/success message.
    setTimeout(() => {
      // this will reset messageSent and isSuccessMessage state
      setMessageSent(false)
      setIsSuccessMessage(false)
    }, 3000)
    // this effect function will be dispatched when isSuccessMessage or messageSent changes its state
  }, [isSuccessMessage, messageSent])

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFieldset>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="firstName">
            <StyledLabelHeader
              required
              isEmpty={errors.firstName && touched.firstName}
            >
              First Name
            </StyledLabelHeader>
            <StyledInput
              id="firstName"
              name="firstName"
              type="text"
              onChange={handleChange}
              value={values.firstName}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.firstName && touched.firstName ? (
            <span className="error-message">{errors.firstName}</span>
          ) : null}
        </StyledFormElementWrapper>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="lastName">
            <StyledLabelHeader
              required
              isEmpty={errors.lastName && touched.lastName}
            >
              Last Name
            </StyledLabelHeader>
            <StyledInput
              id="lastName"
              name="lastName"
              type="text"
              onChange={handleChange}
              value={values.lastName}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.lastName && touched.lastName ? (
            <span className="error-message">{errors.lastName}</span>
          ) : null}
        </StyledFormElementWrapper>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="email">
            <StyledLabelHeader
              required
              isEmpty={errors.email && touched.email}
            >
              Email Address
            </StyledLabelHeader>
            <StyledInput
              id="email"
              name="email"
              type="email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur("email")}
              required
            />
          </StyledLabel>
          {errors.email && touched.email ? (
            <span className="error-message">{errors.email}</span>
          ) : null}
        </StyledFormElementWrapper>

        <h3 style={{'margin-bottom':'1.6rem'}}>What are your top 3 main health goals/concerns</h3>
        <p style={{'margin-bottom':'1.6rem'}}>Please can you describe what you would like Kaye to address in your Personal Health Code.</p>
        <StyledFormElementWrapper>
        
          <StyledLabel htmlFor="healthgoal1">
            <StyledLabelHeader
              required
              isEmpty={errors.healthgoal1 && touched.healthgoal1}
            >
              Health Goal #1
            </StyledLabelHeader>
            <StyledTextArea
              id="healthgoal1"
              name="healthgoal1"
              type="text"
              onChange={handleChange}
              value={values.healthgoal1}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.healthgoal1 && touched.healthgoal1 ? (
            <span className="error-message">{errors.healthgoal1}</span>
          ) : null}
        </StyledFormElementWrapper>

        <StyledFormElementWrapper>
          <StyledLabel htmlFor="healthgoal2">
            <StyledLabelHeader>
              Health Goal #2</StyledLabelHeader>
            <StyledTextArea
              id="healthgoal2"
              name="healthgoal2"
              type="text"
              onChange={handleChange}
              value={values.healthgoal2}
              onBlur={handleBlur}
            />
          </StyledLabel>
        </StyledFormElementWrapper>

        <StyledFormElementWrapper>
          <StyledLabel htmlFor="healthgoal3">
            <StyledLabelHeader
              required
              isEmpty={errors.healthgoal3 && touched.healthgoal3}
            >
              Health Goal #3</StyledLabelHeader>
            <StyledTextArea
              id="healthgoal3"
              name="healthgoal3"
              type="text"
              onChange={handleChange}
              value={values.healthgoal3}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.healthgoal3 && touched.healthgoal3 ? (
            <span className="error-message">{errors.healthgoal3}</span>
          ) : null}
        </StyledFormElementWrapper>




      </StyledFieldset>
      <StyledButton
        type="submit"
        value="Send Message"
        disabled={isSubmitting || !isFormValid}
      >
        Submit
      </StyledButton>
      {messageSent && !isSuccessMessage && (
        <ErrorMessage>
          <p>Something went wrong please try again.</p>
        </ErrorMessage>
      )}
    </StyledForm>
  )
}

export default FhtjHealingAccessCodeForm
